<template>
    <div class="popup-request-confirmation" v-if="translates && translates[langUrl]">
        <div class="popup-request-confirmation__title">{{ translates[langUrl].popupTitle[lang] }}</div>
        <div class="popup-request-confirmation__close" @click="close"></div>
        <div class="popup-request-confirmation__links" v-if="tripRequestActive && tripRequestActive.trip">
            <!-- <a @click.stop="openTrip()" class="popup-request-confirmation__links__item" >Ссылка на образец тура</a> -->
            <!-- <a class="popup-request-confirmation__links__item">Ссылка на конкретный тур</a>
            <a class="popup-request-confirmation__links__item">Редактировать тур в конфигураторе</a> -->
        </div>
        <div class="popup-request-confirmation__info">
            <ValidationObserver ref="validator" slim>
                <ValidationProvider rules="required" v-slot="{ errors }">
                    <BaseSelect
                        :label="translates[langUrl].trip[lang]"
                        :value="bloggerTrip.trip"
                        :items="filteredTrips"
                        item-text="name"
                        item-value="_id"
                        :error-messages="errors[0]"
                        @input="setTrip"
                        :readonly="!tripRequestActive.common" />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseDatePicker
                        :label="translates[langUrl].start[lang]"
                        :value="bloggerTrip.start"
                        @change="setStart"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        class="label-on"
                        v-model="bloggerTrip.duration"
                        :label="translates[langUrl].duration[lang]"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }" rules="required">
                    <BaseTextField
                        class="label-on"
                        v-model="bloggerTrip.price"
                        :label="translates[langUrl].priceForPersone[lang]"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>

                <ValidationProvider v-slot="{ errors }">
                    <BaseTextArea
                        class="label-on"
                        :label="translates[langUrl].workshops[lang]"
                        v-model="bloggerTrip.workshops"
                        :error-messages="errors[0]"
                    />
                </ValidationProvider>
                
                <BaseButton
                    class="trip-request__step-buttons__button button-fill"
                    style="width: 100%"
                    @click="save"
                >{{ translates[langUrl].send[lang] }}</BaseButton>
            </ValidationObserver>
        </div>
        <!-- <BaseButton
            class="button-fill"
            style="width:300px"
            @click="close"
        >eej</BaseButton> -->
    </div>
</template>

<script>
    import moment from '@/plugins/moment';
    import store from '@/store';
    import { mapState } from 'vuex';
    import { TRIP_STATUS, TRIP_TYPE, BLOGGER_TRIP_STATUS, TRIP_REQUEST_STATUS } from '@/vars';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';

    import BaseButton from '../../components/common/BaseButton.vue';
    import BaseSelect from '../../components/common/BaseSelect.vue';
    import BaseTextArea from '../../components/common/BaseTextArea.vue';
    import BaseTextField from '../../components/common/BaseTextField.vue';
    import BaseDatePicker from '../../components/common/BaseDatePicker.vue';
    import BaseMultipleSelect from '../../components/common/BaseMultipleSelect.vue';

    export default {
        name: 'PopupRequestConfirmation',
        components: {
            ValidationProvider,
            ValidationObserver,
            BaseButton,
            BaseTextField,
            BaseTextArea,
            BaseSelect,
            BaseDatePicker,
            BaseMultipleSelect
        },
        data: () => ({
            bloggerTrip: {
                trip: null,
                blogger: null,
                organizer: null,
                tripRequest: null,
                price: null,
                start: '',
                finish: '',
                duration: '',
                activities: '',
                workshops: '',
            },
            tripRequestActive: {},
            langUrl: '/operator/trip-requests',
        }),
        props: {
            tripRequest: {
                type: Object,
                default: {}
            },
        },
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            ...mapState('trips', {
                trips: state => state.entities,
                tripsCount: state => state.entitiesCount,
            }),
            ...mapState('auth', {
                user: state => state.user
            }), 
            filteredTrips() {
                return this.tripRequestActive.common ? this.trips.filter(item => (item.status === TRIP_STATUS.ACTIVE && item.type === TRIP_TYPE.HOST)) : this.trips;
            },
            supportLink() {
                return `https://t.me/${process.env.VUE_APP_TELEGRAM_SUPPORT_BOT}`;
            },
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY;
            },
        },
        async mounted() {
            await this.setBloggerTrip(this.tripRequest);
        },
        watch: {
            tripRequest(newVal, oldVal) {
                this.bloggerTrip = {
                    trip: null,
                    blogger: null,
                    organizer: null,
                    tripRequest: null,
                    price: null,
                    start: '',
                    finish: '',
                    duration: '',
                    activities: '',
                    workshops: '',
                }
                if(newVal) {
                    this.setBloggerTrip(newVal);
                }
            }
        },
        methods: {
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async goTo(name) {
                await this.$router.push({ name });
            },
            async openTrip() {
                await this.$router.push({ name: 'trip-edit' }, { params: { id: this.tripRequest.trip._id }});
            },
            async save() {
                const valid = await this.$refs['validator'].validate();
                if(valid) {
                    const tripRequest = { ...this.tripRequestActive };
                    if(this.tripRequestActive.trip.type !== TRIP_TYPE.TOUR_BLOGGER) {
                        const tourBlogger = await store.dispatch('trips/copy', { id: this.tripRequestActive.trip._id });
                        tourBlogger.type = TRIP_TYPE.TOUR_BLOGGER;
                        // console.log(tourBlogger);
                        if(this.lang === 'ru') {
                            tourBlogger.name = this.tripRequestActive.trip.name + ' с блогером ' + this.tripRequestActive.blogger.firstname +' ' + this.tripRequestActive.blogger.lastname;
                        } else {
                            tourBlogger.name = this.tripRequestActive.trip.name + ' with ' + this.tripRequestActive.blogger.firstname +' ' + this.tripRequestActive.blogger.lastname;
                        }
                        await store.commit('trips/SET_ENTITY', tourBlogger);
                        await store.dispatch('trips/save');
                        this.bloggerTrip.trip = tourBlogger._id;
                        tripRequest.trip = tourBlogger._id;
                        await store.commit('tripRequests/SET_ENTITY', tripRequest);
                        await store.dispatch('tripRequests/save');
                    }
                    this.bloggerTrip.finish = moment(this.bloggerTrip.start).add(+(this.bloggerTrip.duration - 1), 'days');
                    store.commit('bloggerTrips/SET_ENTITY', {
                        ...this.bloggerTrip
                    });
                    await store.dispatch('bloggerTrips/save');
                    
                    if(!this.tripRequestActive.common) {
                        tripRequest.status = TRIP_REQUEST_STATUS.ANSWERED;
                        await store.commit('tripRequests/SET_ENTITY', tripRequest);
                        await store.dispatch('tripRequests/save');
                    }
                    await store.dispatch('tripRequests/fetchOrganizer');

                    this.close();
                }
            },
            async setBloggerTrip(tripRequest) {
                if(tripRequest) {
                    const bloggerTrip = await store.dispatch('bloggerTrips/getByTripRequest', {
                        blogger: tripRequest.blogger._id, 
                        tripRequest: tripRequest._id
                    });
                    this.bloggerTrip = {
                        ...this.bloggerTrip,
                        ...{
                            status: BLOGGER_TRIP_STATUS.BLOGGER_MODERATED,
                            blogger: tripRequest.blogger._id,
                            organizer: this.user._id,
                            tripRequest: tripRequest._id,
                            bloggerAward: tripRequest.bloggerAward,
                            bloggerPlaces: tripRequest.bloggerPlaces,
                            start: tripRequest.start,
                            activities: tripRequest.activities,
                            workshops: tripRequest.workshops,
                            duration: tripRequest.duration
                        }
                    }   
                    if(tripRequest.trip) {
                        this.bloggerTrip.trip = tripRequest.trip._id;
                        this.bloggerTrip.price = tripRequest.trip.price;
                    } else {
                        this.bloggerTrip.trip = this.filteredTrips.length ? this.filteredTrips[0]._id : null;
                    }
                    this.bloggerTrip = {
                        ...this.bloggerTrip,
                        ...bloggerTrip
                    }
                    this.tripRequestActive = tripRequest;
                }
            },
            complete() {
                this.container.main = false;
                this.container.complete = true;
            },
            setTrip(value) {
                this.bloggerTrip.trip = value;
                this.tripRequestActive.trip = this.filteredTrips.find(item => item._id === value);
            },
            setStart(value) {
                this.bloggerTrip.start = value;
            },
            close() {
                this.$emit('close')
            }
        }
    }
</script>

<style lang="scss">
    .popup-request-confirmation {
        position: relative;
        max-width: 620px;
        padding: 32px;
        background-color: #fff;
        font-family: 'Inter';
        font-style: normal;
        display: flex;
        flex-direction: column;
        @media all and (max-width: 768px) {
            padding: 36px 20px;
            border-radius: 12px;
        }
        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 140%;
            color: #000000;
            margin: 0 auto 12px 0;
        }
        &__save {
            width: 100%;
        }
        &__close {
            position: absolute;
            top: 25px;
            right: 25px;
            width: 30px;
            height: 30px;
            background: url(../../assets/PlusGrey.svg);
            background-size: contain;
            transform: rotate(45deg);
            cursor: pointer;
        }
        &__links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 32px;
            &__item {
                text-decoration: underline;
                font-weight: 400;
                font-size: 12px;
                line-height: 140%;
            }
        }
        &__sended {
            display: flex;
            flex-direction: column;
            align-items: center;
            &__text {
                font-weight: 400;
                font-size: 16px;
                line-height: 140%;
                text-align: center;
                letter-spacing: -0.02em;
                color: #A9AEB2;
                margin: 0 auto 25px;
                max-width: 500px;
            }
        }
    }
</style>
