<template>
    <div class="view-trip-requests" v-if="translates && translates[langUrl]">
        <nav v-if="isDesktop && translates.navigation" class="navigation">
            <div class="link" @click="mainPage">{{ translates.navigation.button_route_main[lang] }} ></div>
            <div class="link" @click="tripRequestsLink">{{ translates.navigation.button_route_tripRequests[lang] }}</div>
        </nav>
        <div class="view-trip-requests__title-container">
            <h1 class="view-trip-requests__title">{{ translates[langUrl].pageTitle[lang] }}</h1>
            <!-- <div class="view-trip-requests__pages">
                <div class="view-trip-requests__pages__item active">{{ translates[langUrl].requestsFrom_bloggers[lang] }}</div>
                <div class="view-trip-requests__pages__item">{{ translates[langUrl].requestsFrom_travelers[lang] }}</div>
            </div> -->
        </div>
        <div class="view-trip-requests__content" v-if="loaded">
            <div v-if="tripRequests.length < 1" class="view-trip-requests__content__empty">{{ translates[langUrl].noRequests[lang] }}</div>
            <template v-for="(tripRequest, i) in tripRequests">
                <TripRequest
                    :key="'tripRequest' + i"
                    :trip-request="tripRequest"
                    :show-header="true"
                    :show-button="true"
                    @requestConfirmation="openRequestConfirmation(tripRequest)"
                />
            </template>
        </div>
        <v-dialog
            v-model="confirmationRequest"
            max-width="620px"
        >
            <PopupRequestConfirmation
                :tripRequest="tripRequestActive"
                @close="closeRequestConfirmation"
            />
        </v-dialog>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { priceFormated, lastDigitToWord } from '@/helpers';
    import { TRIP_TYPE, TRIP_STATUS } from '@/vars';

    import BaseButton from '../../components/common/BaseButton.vue';
    import TripRequest from '../../components/common/TripRequest.vue';
    import PopupRequestConfirmation from '../../components/common/PopupRequestConfirmation.vue';

    export default {
        name: 'TripRequests',
        metaInfo() {
            return {
                title: this.translates && this.translates[this.langUrl] ? this.translates[this.langUrl].browserTitle[this.lang] : ''
            }
        },
        components: {
            BaseButton,
            TripRequest,
            PopupRequestConfirmation
        },
        data: () => ({
            TRIP_TYPE, 
            TRIP_STATUS,
            loaded: false,
            priceFormated,
            lastDigitToWord,
            isDesktop: false,
            confirmationRequest: false,
            tripRequestActive: null,
            bloggerTrip: {
                trip: null,
                blogger: null,
                organizer: null,
                tripRequest: null,
                price: null,
                start: '',
                finish: '',
            },
            langUrl: '/operator/trip-requests'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('tripRequests', {
                tripRequests: state => state.entities
            }),
            ...mapState('quiz', ['dictionaries']),
            ...mapState('translates', {
                translates: state => state.entities
            })
        },
        created() {
            window.addEventListener('resize', this.onResize);
            this.onResize();
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        async mounted() {
            this.$root.$emit('preloaderShow');
            store.commit('trips/CLEAR_ENTITIES');
            await store.dispatch('tripRequests/dictionaries');
            await store.dispatch('tripRequests/fetchOrganizer');
            await this.getTrips();
            this.loaded = true;
            this.$root.$emit('preloaderHide');
        },
        methods: {
            onResize() {
                this.isDesktop = window.innerWidth >= 768;
            },
            async getTrips() {
                await store.dispatch('trips/fetch', { 
                    filter: {
                        user: this.user._id,
                        type: [ TRIP_TYPE.HOST, TRIP_TYPE.TOUR_BLOGGER ],
                    }, 
                    sorting: this.sorting, page: 1, itemsPerPage: 10000
                });
            },
            async chooseTrip(tripRequest) {
                await this.$router.push({ 
                    name: 'operator-blogger-trip-choose',
                    params: { tripRequestId: tripRequest._id }
                });
            },
            formattedDirections(value) {
                return value && this.dictionaries.directions ? this.dictionaries.directions.find(item => (item.code.toString() === value.toString())).title : '';
            },
            formattedDirectionsAdditional(value) {
                return value && this.dictionaries.directionsAdditional ? this.dictionaries.directionsAdditional.find(item => (item.code.toString() === value.toString())).title : '';
            },
            formattedComfort(value) {
                return value && this.dictionaries.comforts ? this.dictionaries.comforts.find(item => (item.code.toString() === value.toString())).title : '';
            },
            // totalPrice(price, bloggerAward) {
            //     return price + +bloggerAward
            // },
            activities(tripRequest) {
                if(this.dictionaries.activities) {
                    return tripRequest.activities.map(item => {
                        return this.dictionaries.activities.find(a => a.code.toString() === item.toString()).title;
                    }).join(', ');
                }
                return '';
            },
            async tripRequestsLink () {
                await this.$router.push({ name: 'operator-trip-requests' });
            },
            async mainPage () {
                await this.$router.push({ name: 'home' });
            },
            openRequestConfirmation(tripRequest) {
                this.tripRequestActive = tripRequest;
                this.confirmationRequest = true;
                // console.log(this.tripRequestActive)
            },
            closeRequestConfirmation() {
                this.tripRequestActive = null;
                this.confirmationRequest = false;
            }
        }
    }
</script>

<style lang="scss">
    .view-trip-requests {
        @media all and (min-width: 768px) {
            padding: 0 32px;
        }
        @media all and (min-width:1464px) {
            padding: 0;
        }
        &__menu{
            margin: 13px 24px 0;
            font-size: 12px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            @media all and (min-width:768px) {
                margin: 13px 0 0;
            }
        }
        &__title-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        &__title {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #3A4256;
            margin: 32px 24px 12px;
            @media all and (min-width:768px) {
            font-weight: bold;
            font-size: 38px;
            line-height: 46px;
            color: #273155;
            margin: 40px 0 36px;
            }
        }
        &__pages {
            display: flex;
            flex-direction: row;
            align-items: center;
            &__item {
                margin-right: 32px;
                font-weight: 600;
                font-size: 18px;
                line-height: 140%;
                color: #A1A4B1;
                cursor: pointer;
                &.active {
                    font-size: 28px;
                    color: #273155;
                }
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            
            @media all and (min-width: 768px) {
                flex-direction: row;
                margin-right: -32px;
            }

            &__empty {
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                color: #3A4256;
                text-align: center;
                margin: 10vw auto;
            }
        }
    }
</style>